import React from "react";
import { CiCircleInfo } from "react-icons/ci";
import { IconContext } from "react-icons";

class InfoIcon extends React.Component {

    // constructor(props){
    //     super(props)
    // }

  render() {
    return (
        <IconContext.Provider value={{ color: "#36b9cc", className: "global-class-name", size: "1.5em" }}>
        <div>
          <CiCircleInfo />
          {" "+this.props.desc}
        </div>
      </IconContext.Provider>
    );
  }
}

export default InfoIcon;