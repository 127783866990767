import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//Pages
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import UserInfo from "./pages/UserInfo";
import GeneList from "./pages/GeneList";
import history from './api/history';
import OTP from "./pages/SignIn/otp";
import Demo from "./pages/Demo"
import DemoDetails from "./pages/Demo/details"
import DemoInfo from "./pages/Demo/info"

const Routes = () => {
    return (
        <BrowserRouter history={history}>
            <Switch>
                <Redirect exact from="/" to="/signin" />
                <Route exact path="/signin" component={SignIn} />
                <Route path="/signin/verification" component={OTP} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/userinfo" component={UserInfo} />
                <Route path="/genelist" component={GeneList} />
                <Route path="/demo" component={Demo} />
                <Route path="/demo-details" component={DemoDetails} />
                <Route path="/demo-info" component={DemoInfo} />
                <Route path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
};

export default Routes;
