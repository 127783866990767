import React from "react";
import { CiCircleChevLeft } from "react-icons/ci";
import { IconContext } from "react-icons";

class ChevLeftIcon extends React.Component {

    // constructor(props){
    //     super(props)
    // }

  render() {
    return (
        <IconContext.Provider value={{ color: "", className: "global-class-name", size: "2em" }}>
        <div>
          <CiCircleChevLeft />
          {" "+this.props.desc}
        </div>
      </IconContext.Provider>
    );
  }
}

export default ChevLeftIcon;