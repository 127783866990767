import React from "react";
import { CiCircleMinus } from "react-icons/ci";
import { IconContext } from "react-icons";


class MinusIcon extends React.Component {
    // constructor(props){
    //     super(props)
    // }

  render() {
    return (
        <IconContext.Provider value={{ color: "red", className: "global-class-name", size: "1.5em" }}>
        <div>
          <CiCircleMinus />
          {" "+this.props.desc}
        </div>
      </IconContext.Provider>
    );
  }
}

export default MinusIcon;