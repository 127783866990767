import React from "react";
import { useLocation , Link } from "react-router-dom";

//Navigation
import Topbar from "../../components/Navigation/Topbar/index";
import CardBasic from "../../components/Cards/Basic";
import ChevLeftIcon from "../../components/Icon/ChevLeft/index";
import Footer from "../Footer/index"
import { convertDateDDMMYYYY } from "../../api/helper";

function UserInfo() {
  const location = useLocation();
  const data = location.state?.data;

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}

              {/* <PageHeading title="PGx Labs" /> */}

              <div className="row ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <CardBasic>
                  <div className="row ">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <Link to='/dashboard' ><ChevLeftIcon desc=""></ChevLeftIcon></Link>
                    </div>
                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
                      <h3 style={{color:"#4E4B4B"}} >PGx Labs</h3>
                    </div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    </div>
                  </div>
                  </CardBasic>
                </div>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <CardBasic title="Infomation">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td className="font-weight-bold td_bg">User</td>
                          <td>{data.profile_name}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold td_bg">DOB</td>
                          <td>
                            {data.birthday} (Age: {calculateAge(data.birthday)})
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold td_bg">Sampling</td>
                          <td>{data.sample_id}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold td_bg">Method</td>
                          <td>{data.method}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold td_bg">
                            Report By
                          </td>
                          <td>{data.report_by} <br/>{data.report_position}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold td_bg">
                            Approved By
                          </td>
                          <td>{data.approved_by}<br/>{data.approved_position}<br/>Date: {convertDateDDMMYYYY(data.created)}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold td_bg">
                            Hospital
                          </td>
                          <td >
                              {data.created_by_name}<br/>
                              {data.hospital_sub_name}<br/>
                              {data.hospital_address}<br/>
                              Tel. {data.hospital_phone}
                              
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBasic>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <Footer/>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
}

export default UserInfo;
