import React from "react";
import OTPInput from "otp-input-react";
import { useState } from 'react';
import kinyoodeeImg from "../../../assets/img/kinyoodee.png";
import { confirmOTP, sendOTP } from "../../../api/helper";
import Swal from "sweetalert2";
import { useEffect } from "react";


export default function OTP () {
    const [otp, setOTP] = useState("");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const phone = urlParams.get('phone');
    const submit = async (e) => {
        e.preventDefault();
        let check = await confirmOTP({ phone_number: phone, code: parseInt(otp) });
        if (check.error === 0 && check.data.status === 1) {
            window.localStorage.setItem('number', phone);
            window.location.href = `/dashboard`
        } else {
            Swal.fire({
                icon: 'error',
                title: "Fails",
                text: check.data.errmsg
            });
        }
    };
    const resendCode = async () => {
    const otp = await sendOTP({ phone_number: phone});
        if (otp.error === 0) {
            Swal.fire({
                icon: 'success',
                title: "Success",
            });
        }
    };
    useEffect(() => {
        const number = window.localStorage.getItem('number');
        if (number) {
          window.location.href = '/dashboard';
        }
    });
    return (
        <>
            <div className="container" style={{height : "100%"}}>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 align-middle">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0 my-5">
                                <div className="row">
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"/>
                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                            <div className="p-2">
                                                <div className="text-center">
                                                    <img src={kinyoodeeImg} style={{ height: "30%", width: "30%" }} alt="logo" />
                                                </div>
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4 mt-4" style={{ fontWeight: "bold" }}>KinYooDee Platform</h1>
                                                </div>
                                            </div>
                                            <h5 className="text-center text-gray-900">Verification Code</h5>
                                            <div className="flex flex-col text-center ">
                                                <span>Enter the OTP you received at</span>
                                                <span style={{ fontWeight: "bold" }}> { phone }</span>
                                            </div>
                                            <div className="d-flex flex-row justify-content-center text-center px-2 mt-4">
                                            <OTPInput value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={false} 
                                                inputStyles={{ border: 'solid 1px gray', width: 50, height: 50 }} />
                                            </div>
                                            <div className="flex justify-center text-center my-4">
                                                Didn't receive OTP?
                                                <a href="#" onClick={() => resendCode()} className="flex items-center pl-1" style={{ borderRadius: "100px" }}>
                                                    <span className="font-bold">Resend code</span><i className='bx bx-caret-right ml-1'></i>
                                                </a>
                                            </div>
                                            <form className="user" onClick={(e) => submit(e)}>
                                                <button type="submit" className="btn btn-primary btn-user btn-block"  style={{ fontSize: "16px" }}>
                                                    Submit                   
                                                </button>
                                            </form>
                                        </div>
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}