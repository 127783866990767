import React from 'react';
import ReactStars from 'react-stars'

class Star extends React.Component {

    constructor(props){
        super(props)
    }

  render() {
    return (
      <ReactStars
      // count={this.props.count}
      count={this.props.value}
      value={this.props.value}
      //onChange={ratingChanged}
      edit={false}
      size={24}
      color2={'#ffd700'} />
    );
  }
}

export default Star;